.sudoku-grid {
    border-collapse: collapse;
    margin: 0 auto;  
  }
  
  .sudoku-grid td {
    width: 30px;
    height: 30px;
    padding: 0;
  }

.sudoku-cell {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    padding: 0;
  }

  .sudoku-row {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;  
  }

  .sudoku-row-box-top {
    border-top: 2px solid black;
    border-bottom: 1px solid gray;
  }

  .sudoku-row-box-bottom {
    border-bottom: 2px solid black;
    border-top: 1px solid gray;
  }

  .sudoku-cell-box-left {
    border-left: 2px solid black;
    border-right: 1px solid gray;
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    padding: 0;
  }

  .sudoku-cell-box-right {
    border-right: 2px solid black;
    border-left: 1px solid gray;
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    padding: 0;
  }

.sudoku-cell.selected {
    background-color: green;
    color: blue;
  }
  
  .sudoku-cell.conflict {
    background-color: #ffcccc;
    border: 1px solid #ff0000;
  }
  
  .sudoku-cell:focus {
    outline: none;
  }

  input[type="number"] {
  -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  
  input[type="number"]:read-only {
    background-color: #f0f0f0;
    font-weight: bold;
    color: purple;
  }

  .navigationButtons {
    margin: 4px;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }


  
  
