* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px;
  padding: 0;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

.square {
  border: 1px solid #999;
  float: left;
  font-size: 28px;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}

.square0 {
  background: #ffffff;
  color: #000000;
}

.square0Winner {
  background: #009900;
  color: #ffffff;
}

.square1 {
  background: #ffffff;
  color: #000000;
}

.square1Winner {
  background: #009900;
  color: #ffffff;
}

.square2 {
  background: #ffffff;
  color: #000000;
}

.square2Winner {
  background: #009900;
  color: #ffffff;
}

.square3 {
  background: #ffffff;
  color: #000000;
}

.square3Winner {
  background: #009900;
  color: #ffffff;
}

.square4 {
  background: #ffffff;
  color: #000000;
}

.square4Winner {
  background: #009900;
  color: #ffffff;
}

.square5 {
  background: #ffffff;
  color: #000000;
}

.square5Winner {
  background: #009900;
  color: #ffffff;
}

.square6 {
  background: #ffffff;
  color: #000000;
}

.square6Winner {
  background: #009900;
  color: #ffffff;
}

.square7 {
  background: #ffffff;
  color: #000000;
}

.square7Winner {
  background: #009900;
  color: #ffffff;
}

.square8 {
  background: #ffffff;
  color: #000000;
}

.square8Winner {
  background: #009900;
  color: #ffffff;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
  font-family: "Webdings";
}

.status {
  margin-bottom: 10px;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.game-navigation {
  margin-top: 10px;
}

.player-selection {
  margin-bottom: 10px;
}

.player-dropdown {
  width: 40px;
}